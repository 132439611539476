import React, { ReactNode } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./CustomButton.css";

interface CustomButtonProps {
  disabled?: boolean;
  value?: string;
  className?: string;
  id?: string;
  text: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  disabled,
  value,
  className,
  id,
  text,
}) => {
  return (
    <div>
      <button
        disabled={disabled}
        className={className}
        id={id}
        style={{ display: "flex", gap: "5px", alignItems:'center',justifyContent:'center'}}>
        {disabled && <CircularProgress size="18px" />}
        <span>{text}</span>
      </button>
    </div>
  );
};

export default CustomButton;
