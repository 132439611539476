import { useEffect, useState } from "react";
import style from "./wrapper.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../redux/userSlice";
import { RootState } from "../../../../redux/store";

import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
type propType = {
  activeTab: number;
  setActiveTab: (val: number) => void;
  setHead:any;
};
function SideBar({ activeTab, setActiveTab,setHead }: propType) {
  const user = useSelector((state: RootState) => state.user.user);
  console.log("profile", user?.name);
  const handleClick = (index: number) => {
    setActiveTab(index);
  };
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const [showSidebar, setShowSidebar] = useState(true);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (typeof window !== "undefined" && window.innerWidth <= 600) {
  //       setIsOpen(false);
  //     }
  //   };

  //   if (typeof window !== "undefined") {
  //     window.addEventListener("resize", handleResize);

  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }
  // }, []);
  const dispatch = useDispatch();
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const renderHead = () => {
    const tabHeads:any = {
      1: `Welcome Dashboard ${user?.name}`,
      2: `User Management`,
      3: `All Sessions`,
      4: `All Programs`,
      5: `All Groups`,
      10: `Payment & Bills`,
      6: `Support Chat`,
      9: `FAQ's`,
      7: `My Settings`,
      11: `My Blogs`,
      12: `Admin Management`,
    };
    setHead(tabHeads[activeTab]);
  };
  useEffect(()=>{
    renderHead()
  },[activeTab])
  return (
    <>
      {/* main content */}
      <button onClick={toggleSidebar} className={style.toggleButton}>
        {showSidebar ? (
          <ViewSidebarIcon className={style.HideButton} />
        ) : (
          <ViewSidebarIcon />
        )}
      </button>
     
      <div className={style.sidebarContainer}>
        <div className={style.MenuToggleOpen}>
          <ViewSidebarIcon />
        </div>
        {showSidebar ? (
      <div className={style.sidebar}>
        <div className={style.smartlogo}>
          <div className={style.smarthlogo1} >
            <img
              src="./images/Coach/login/white-logo.png"
              className={style.whitebg}
            />
          </div>
          <div className={style.siderbarcontent}>
            <div className={style.content}>
              <div className={style.profile}>
                {/* profile css  */}
                {/* <img src="./images/Coach/login/Shape.png" /> */}
                <div className={style.uname}>
                  <h2>{user?.name}</h2>
                </div>
              </div>
              <div className={style.contentitem}>
                <ul className={style.tabCnt}>
                  <li
                    onClick={() => handleClick(1)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 1 && style.lidesign}`}>
                      {activeTab === 1 ? (
                        <img src="./images/Coach/login/dashboardwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/dashboard.svg" />
                      )}
                      Dashboard
                    </div>
                  </li>

                  <li
                    onClick={() => handleClick(2)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 2 && style.lidesign}`}>
                      {activeTab === 2 ? (
                        <img src=" /images/svg/Coachee/myCoachWhite.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/myCoach.svg" />
                      )}
                      User Management
                    </div>
                  </li>

                  <li
                    onClick={() => handleClick(3)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 3 && style.lidesign}`}>
                      {activeTab === 3 ? (
                        <img src="./images/Coach/login/sessionwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/session.svg" />
                      )}
                      All sessions
                    </div>
                  </li>

                  <li
                    onClick={() => handleClick(4)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 4 && style.lidesign}`}>
                      {activeTab === 4 ? (
                        <img src="/images/svg/Coachee/whiteMyRepo.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/report.svg" />
                      )}{" "}
                      All Programs
                    </div>
                  </li>

                  <li
                    onClick={() => handleClick(5)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 5 && style.lidesign}`}>
                      {activeTab === 5 ? (
                        <img src="./images/Coach/login/groupwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/group.svg" />
                      )}{" "}
                      All Group 
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(11)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 11 && style.lidesign}`}>
                      {activeTab === 11 ? (
                           <img src="/images/svg/Coachee/whiteMyRepo.svg" />
                          ) : (
                            <img src="/images/svg/Coachee/report.svg" />
                      )}{" "}
                    Blogs
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(10)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 10 && style.lidesign}`}>
                      {activeTab === 10 ? (
                        <img src="./images/Coach/login/groupwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/group.svg" />
                      )}{" "}
                     Payment & Billing
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(6)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 6 && style.lidesign}`}>
                      {activeTab === 6 ? (
                        <img src="/images/svg/Coachee/whiteMark.svg" />
                      ) : (
                        <img src="/images/svg/Coachee/mark.svg" />
                      )}{" "}
                      Support Chat
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(9)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 9 && style.lidesign}`}>
                      {activeTab === 9 ? (
                        <img src="/images/svg/Coachee/whiteMark.svg" />
                      ) : (
                        <img src="./images/Coach/login/group.svg" />
                      )}{" "}
                    FAQ's 
                    </div>
                  </li>

                  <li
                    onClick={() => handleClick(7)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 7 && style.lidesign}`}>
                      {activeTab === 7 ? (
                        <img src="./images/Coach/login/settingwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/settings.svg" />
                      )}{" "}
                      My Settings
                    </div>
                  </li>
                  <li
                    onClick={() => handleClick(12)}
                    className={`${style.tabDesign}`}
                  >
                    <div className={`${activeTab === 12 && style.lidesign}`}>
                      {activeTab === 12 ? (
                       <img src="./images/Coach/login/groupwhite.svg" />
                      ) : (
                        <img src="./images/Coach/login/group.svg" />
                      )}{" "}
                      Admin Management
                    </div>
                  </li>
                  
                </ul>  
                <hr />
                <div className={style.about}>
                  <ul>
                    <li onClick={() => dispatch(logout())}>logout</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
          <div className={style.Sidebar2}></div>
        )}
      </div>
    </>
  );
}

export default SideBar;
