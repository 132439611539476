import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { fetchCoacheeGroupId } from "../../../../Api";
import style from "../CSS/group.module.css"
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
type Proptype={
    grpId:any
}
const ShowGroups=({grpId}:Proptype)=>{
    const dayjs = require('dayjs');
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const[group,setGroup]=useState<any>([])
  
    useEffect(()=>{
        fetchCoacheeGroupId(cookie,grpId)
        .then((res:any)=>{
            console.log("This is Group",res)
            setGroup(res)
         
        
        })
    },[cookie])
  
    return (<>
      <div className={style.groupContent}>
       
 
    <>
    <div className={style.groupDataShowContent}>
    <div className={style.groupDataShow}>
    <h5>{group.data?.groupTopic}</h5>
    <p><AccessAlarmsIcon/> { dayjs(group.data?.createdAt.slice(0,10)).format('DD MMM YYYY')}</p>
    </div>
    <div className={style.groupsessionNuShow}>
    <div className={style.groupsessionNuShowDetail}>
        <p>Assigned Coach</p>
        <div className={style.groupsessionNuShowDetaiilCont}>
       
       <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${group.data?.coachIds[0].profileImg}`}  className={style.groupsessionNuShowDetailIcm}/>
       <h6>{group.data?.coachIds[0].name.slice(0,13)}</h6>
      
       </div>
    </div>
    <div className={style.groupsessionNuShowDetail}>
        <p>Total Session</p>
        <h6>{group.data?.Sessions.length}</h6>
    </div>
    </div>
    </div>
    <h6>Group Sessions</h6>
    <table>
        <tr>
            <th>SESSION TOPIC</th>
            <th>WHAT ARE WE GOING TO LEARN</th>
            <th>SESSION DURATION  </th>
            <th>DATE</th>
            <th>STATUS</th>
            <th></th>
        </tr>
        <tr>
            <td>Session Topic</td>
            <td>Lorem ipsom is simple dummy text that can goes...</td>
            <td>35 Min</td>
            <td>May 18, 2022</td>
            <td>Completed</td>
            <td><button className={style.groupsessionNuShowbtn}>Enter Session</button></td>
        </tr>
    </table>
    </>
  
</div>
    </>)
    
}
export default ShowGroups;