import { useEffect, useState } from "react";
import styles from "../../../Dashboard/Coach/all-session/session.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CachedIcon from '@mui/icons-material/Cached';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { BlockUser, DeleteUser, fetchCoach } from "../../../../Api";
import { toast } from "react-toastify";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  setCoachUid: (val: (v: any) => any) => void
};
const UM1 = ({ setActiveTab, setCoachUid }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [change, setChange] = useState(true)
  const [coachData, setCoachData] = useState<any[]>([]);

  useEffect(() => {
    fetchCoach(cookie, 2)
      .then((result: any) => {
        setCoachData(result.data.history);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });
  }, [cookie, change]);

  const handleUid = (uid: any) => {
    setCoachUid(uid)
    setActiveTab(() => 3);
  }

  const handleDeleteUser = (_id: string, action: boolean) => {
    if (_id && _id.trim() != "") {
      DeleteUser({ token: cookie, uid: _id, action }).then((res: any) => {
        toast.success(res?.message)
        setChange(!change)
      }).catch((err) => console.log(err)
      )
    }
    else {
      toast.error("Select A User!")
    }

  }
  const handleBlockUser = (_id: string, action: boolean) => {
    if (_id && _id.trim() != "") {
      BlockUser({ token: cookie, uid: _id, action }).then((res: any) => {
        toast.success(res?.message)
        setChange(!change)
      }).catch((err) => console.log(err)
      )
    }
    else {
      toast.error("Select A User!")
    }

  }

  return (
    <div>
      <div className={styles.container}>
        <input
          className={styles.container2}
          type="text"
          placeholder="Search user name here"
          style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
        />
        <div>
          <table className={styles.sessiontable}>
            <tr>
              <th>USER NAME</th> <th>EMAIL</th>
              <th>STATUS</th>
              <th>SESSION</th>
              <th>SESSION COMPLETED</th>
              <th></th>
            </tr>

            {coachData.length > 0 ? (
              coachData.map((coach: any, index: number) => (
                <tr className={styles.sessiontable} key={`${coach._id}_Coach`}>
                  <td className={styles.sessiontableTD}>
                    {coach.profileImg ? (<img
                      src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`}
                      className={styles.coachImg}
                    />) : (<img src="/images/svg/Coach/setting/personal.svg" className={styles.coachImg2} />)}

                    {coach.name}
                  </td>
                  <td>{coach.email}</td>

                  <td className={styles.alignCenter}>
                    {/* {coach.account_status} */}
                    {coach.account_status === 0 ? (<p className={styles.AccStatus0}><HourglassTopIcon />Unverified</p>) : coach.account_status === 1 ?
                      (<p className={styles.AccStatus1}><CachedIcon />Pending</p>) : coach.account_status === 2 ? (<p className={styles.AccStatus2}><ThumbUpAltIcon />Active</p>) : coach.account_status === 3 ?
                        (<p className={styles.AccStatus3}><BlockIcon />Blocked</p>)
                        : coach.account_status === 4 ? (<p className={styles.AccStatus4}><DeleteIcon />Deleted</p>) : (<p className={styles.AccStatus4}><PersonOffIcon />Not Found!</p>)}
                  </td>
                  <td></td>
                  <td></td>
                  <td style={{ display: 'flex' }}>
                    <img src="/images/svg/Admin/viewIcon.svg" onClick={() => handleUid(coach._id)} />
                    {coach.account_status == 3 ?
                      <div onClick={() => handleBlockUser(coach._id, false)} style={{ width: 'fit-content', backgroundColor: '#0C5899', padding: '5px', borderRadius: '10px', marginLeft: '4px' }}>
                        <LockResetIcon style={{ color: 'white' }} />
                      </div> :
                      <img src="/images/svg/Admin/lockIcon.svg" onClick={() => handleBlockUser(coach._id, true)} />
                    }
                    {coach.account_status == 4 ?
                      <div onClick={() => handleDeleteUser(coach._id, false)} style={{ width: 'fit-content', backgroundColor: '#97CC4F', padding: '5px', borderRadius: '10px', marginLeft: '4px' }}>
                        <RestoreFromTrashIcon style={{ color: 'white' }} />
                      </div>
                      :
                      <img src="/images/svg/Admin/deletIcon.svg" onClick={() => handleDeleteUser(coach._id, true)} />
                    }
                  </td>
                </tr>
              ))
            ) : (
              <p>No coach data available</p>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default UM1;
