import { emitKeypressEvents } from "readline";
import { cdn_Link } from "../../../../config";
import styles from "./session.module.css";
import Pagination from "../../../Pagination/Pagination";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

type propType = {
  setActiveTab: (val: (v: number) => number) => void;
  handleSearch: (val: string) => void;
  search: string;
  data: {
    prev: boolean;
    next: boolean;
    search: string;
    page: number;
    total_page: number;
    history: never[];
  };
};
const AsTab1 = ({
  data,
  handleSearch,
  search,
}: propType) => {
  const [isJoinEnabled, setIsJoinEnabled] = useState<any>({});

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedJoinEnabled: any = {};
      data.history?.forEach((elem: any) => {
        elem.sessions?.forEach((session: any) => {
          const currentTime = dayjs();
          const startTime = dayjs(session?.startDate);
          const endTime = dayjs(session?.endDate);

          if (currentTime.isAfter(startTime) && currentTime.isBefore(endTime)) {
            updatedJoinEnabled[elem?._id] = true;
          }
        });
      });
      setIsJoinEnabled(updatedJoinEnabled);
    }, 60000);

    return () => clearInterval(interval);
  }, [data.history]);

  const handleChat = () => {};

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container1}>
          <input
            className={styles.container2}
            type="text"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Which client are you looking for"
            style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
          />
          <div>
            <table className={styles.sessiontable}>
              <tbody>
                <tr>
                  <th>CLIENT NAME</th>
                  <th>TOTAL UPCOMING SESSIONS</th>
                  <th>DATE </th>
                  <th>TIME SLOT</th>
                  <th></th>
                </tr>
                {data.history.length > 0 ? (
                  data.history.map((elem: any, ind: number) => (
                    <tr key={elem?._id}>
                      <td className={styles.showinfoConte}>
                        <img
                          src={
                            elem.CoacheeDetails.profileImg
                              ? `${cdn_Link}/${elem.CoacheeDetails.profileImg}`
                              : "/images/Coach/pic1.png"
                          }
                          alt=""
                          className={styles.showCinfoImg}
                        />
                        <div className={styles.showCInfo}>
                          <span className={styles.showCinofP}>
                            {elem?.CoacheeDetails?.name}
                          </span>
                          <span className={styles.showCinofProg}>
                            {elem?.ProgramData?.name}
                          </span>
                        </div>
                      </td>
                      <td>{elem?.sessions.length} Session</td>
                      <td>Date</td>
                      <td>Time Slot</td>
                      <td>
                        <button
                          disabled={
                            !elem.sessions.some(
                              (session: any) => isJoinEnabled[session._id]
                            )
                          }
                          className={styles.StartSessBtn}>
                          Start Session
                        </button>
                        <button className={styles.SendMsg}>Send Message</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <>No Session</>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default AsTab1;
