import { useEffect, useState } from "react";
import AS1 from "./UserManage/UM1";
import AS2 from "./UserManage/UM2";
import Style from "../../../styles/session.module.css";
import AS3 from "./UserManage/UM3";
const UserManage = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [coachUid, setCoachUid] = useState();

  return (
    <>
      <div className={Style.container}>
        <div className={Style.Artist}>
          {/* {Tab Container} */}

          {
            activeTab != 3 ?
              <ul className={Style.tabCnt1}>
                <li
                  className={`${Style.tab1} ${activeTab === 1 && Style.tabActive1}`}
                  onClick={() => setActiveTab(1)}
                >
                  All Coaches
                </li>
                <li
                  className={`${Style.tab1} ${activeTab === 2 && Style.tabActive1}`}
                  onClick={() => setActiveTab(2)}
                >
                  All Coachees
                </li>
              </ul> : <></>

          }

        </div>
        <div className={Style.Artist1}>
          {activeTab === 1 && <AS1 setActiveTab={setActiveTab} setCoachUid={setCoachUid} />}
          {activeTab === 2 && <AS2 setActiveTab={setActiveTab} setCoachUid={setCoachUid} />}
          {activeTab === 3 && <AS3 setActiveTab={setActiveTab} coachUid={coachUid} />}

        </div>
      </div>

    </>
  );
};
export default UserManage;
