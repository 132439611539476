import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { notifyError } from "../../../Notify";
import dayjs from "dayjs";
import { cdn_Link } from "../../../config";
import { FetchProgramsCoach } from "../../../Api";
import style from "../../../styles/program.module.css";
import ProgramById from "./programs/ProgramById";

const Programs = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [pid,setProgId]=useState('')
const[activeTab,setActiveTab]=useState(0);
const[coacheeData,setCoacheeData]=useState<any>([])
  const [programData, setprogramData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const fetchProgram = () => {
    FetchProgramsCoach({
      cookie,
      page: programData.page,
      search: programData.search,
    })
      .then((result: any) => {
        let res = result.data;
        console.log(res)
        setCoacheeData(res.history)
        let x: any = { ...programData };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setprogramData(x);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProgram();
  }, [cookie]);

  return (
    <>
  {activeTab==0 && (<>  <div className={style.ProContainer}>
        <input
            className={style.container2}
            type="text"
            // value={search}
            // onChange={(e) => handleSearch(e.target.value)}
            placeholder="Which Program are you looking for"
            style={{ border: "1px solid rgba(0, 0, 0, 0.178)" }}
          />
      <div className={style.CardContainer}>
        {programData.history.length > 0 &&
          programData.history.map((pdata: any) => (
            <div className={style.SingleCard}>
              <img
                src={`${cdn_Link}/${
                  pdata.programImg || pdata.Program_Details?.programImg
                }`}
                className={style.ProImage}
              />

              <div className={style.DetailContainer} onClick={()=>{setProgId(pdata._id || pdata.Program_Details?._id);setActiveTab(1);}}>
                <h6
                  style={{
                    color: "black",
                    fontSize: "18px",
                   
                  }}>
                 <b> {pdata.name || pdata.Program_Details?.name}</b>
                </h6>
                <p>{pdata.description.slice(0,30) || pdata.Program_Details?.description.slice(0,30)}</p>
                <div>
                  <p style={{ color: "#0C5899" }}>
                    <CheckCircleIcon />{" "}
                    {dayjs(pdata.createdAt.slice(0, 10)).format("DD MMM YYYY")}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  
    
    </>)}
    {activeTab==1 && (<ProgramById setActiveTab={setActiveTab} pid={pid} coacheeData={coacheeData}/>)}
    </>
  );
};
export default Programs;
