import style from "./chat.module.css";
import AddIcon from "@mui/icons-material/Add";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect, useState, useRef } from "react";
import { Box, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import {
  GetAllMsg,
  GetTickets,
  InitilizeTicket,
  SendMsg,
} from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { notifyError } from "../../../../Notify";

const Chat = () => {
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [msg, setmsg] = useState("");
  const [activechat, setactivechat] = useState("");
  const [messages, setmessages] = useState<any>([]);
  const [issue, setIssue] = useState("");
  const [description, setdescription] = useState("");
  const [viewTicket, setviewTicket] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(1);
  const messagesEndRef = useRef<any>(null);

  const RaiseTicket = async () => {
    try {
      const data = await InitilizeTicket(cookie, description, issue);
      console.log(data);
      setOpen1(false);
      setmsg('');
      setIssue('');
      FetchAllTickets(); // Fetch tickets again to update the list
    } catch (error) {
      console.log("Error At Creating Ticket", error);
    }
  };

  const GetMsgs = async (chatId: any, pageNum: number) => {
    setactivechat(chatId);
    try {
      const data: any = await GetAllMsg(cookie, chatId, pageNum);
      // Append new messages to existing messages
      setmessages((prevMessages: any) => [...prevMessages, ...data.history]);
      console.log("data of chatroom", data);
      setPageLimit(data?.total_page); // Set the page limit from the response
    } catch (error) {
      console.log("Error At Fetching Messages", error);
    }
  };

  const SendMsgs = async () => {
    try {
      const data: any = await SendMsg(cookie, activechat, msg);
      setmsg("");
      GetMsgs(activechat, page); // Fetch messages again
    } catch (error: any) {
      console.error("Error sending message:", error);
      notifyError(error.response?.data?.message || "An error occurred");
    }
  };

  const FetchAllTickets = async () => {
    try {
      const data: any = await GetTickets(cookie);
      if (!data.data) {
        console.log("No Tickets");
      } else {
        setviewTicket(data.data);
        console.log("Tickets Set", data.data.length);
      }
    } catch (error) {
      console.log("Error At Fetching Tickets", error);
    }
  };

  const loadMoreMessages = () => {
    const nextPage = page + 1;
    if (nextPage <= pageLimit) { 
      GetMsgs(activechat, nextPage); 
      setPage(nextPage); 
    }
  };

  const handleTicketClick = (ticket: any) => {
    setmessages([]); 
    setPage(1); 
    GetMsgs(ticket.chatId, 1); 
  };

  useEffect(() => {
    FetchAllTickets();
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div className={style.ChatContainer}>
      <div className={style.Chatparts}>
        <div className={style.ChatpartsLeft}>
          <div className={style.Chathead}>
            <h4>Ticket</h4>
            <button onClick={handleOpen}>
              <AddIcon />
              New
            </button>
            <Modal
              open={open1}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={style.BoxStyle}>
                <span onClick={handleClose} className={style.HandleClose}>
                  <CloseTwoToneIcon className={style.HandleCloseIcon} />
                </span>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Create Ticket
                </Typography>
                <Typography id="modal-modal-description" className={style.typoDesc}>
                  <form>
                    <TextField
                      type="text"
                      id="outlined-basic"
                      label="Ticket Description"
                      variant="outlined"
                      value={description}
                      onChange={(e: any) => setdescription(e.target.value)}
                      className={style.Inputfield}
                      sx={{
                        my: 1,
                        "& .MuiInputBase-input": {
                          width: "100%",
                        },
                        "& .MuiInputLabel-root": {
                          backgroundColor: "#fff",
                        },
                      }}
                    />
                    <textarea
                      placeholder="Describe Your Issue"
                      className={style.InputTextfield}
                      rows={6}
                      value={issue}
                      onChange={(e: any) => setIssue(e.target.value)}
                    ></textarea>
                    <p className={style.TypoButton} onClick={RaiseTicket}>
                      Create New Ticket
                    </p>
                  </form>
                </Typography>
              </Box>
            </Modal>
          </div>
          <div className={style.ChatpartsDisplmsg}>
          {viewTicket && viewTicket.length > 0 ? (
            viewTicket.map((ticket: any) => (
              <div
                key={ticket._id}
                onClick={() => handleTicketClick(ticket)} // Use the new handler
                className={style.TicketDesc}
              >
                <p>{ticket.title}</p>
                <span>{ticket.description.slice(0, 16)}</span>
              </div>
            ))
          ) : (
            <></>
          )}
          </div>
        </div>
        <div className={style.ChatpartsRight}>
          <div className={ messages.length < 7 ?style.chatingSection1 :style.chatingSection}>
            {messages && messages.length > 0 ? (
              <>
                {messages.map((msg: any, index: number) => (
                  <div key={index} className={style.DisplayMsg}>
                    {msg.from === user?._id ? (
                      <div className={style.msgTextLeft}>
                        <p>{msg.text}</p>
                      </div>
                    ) : (
                      <div className={style.msgTextRight}>
                        <p>{msg.text}</p>
                      </div>
                    )}
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </>
            ) : (
              <></>
            )}
          </div>
          {/* Conditional rendering of the Load More button */}
          <div className={style.loadMoreMsg}>
          {page < pageLimit && (
            <button onClick={loadMoreMessages} className={style.LoadMoreButton}>
              Load More
            </button>
          )}
          </div>
          <div className={style.SendMsgButton}>
            <input
              type="text"
              value={msg}
              onChange={(e: any) => setmsg(e.target.value)}
            />
            <button onClick={SendMsgs}>
              <SendIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
