import React, { useEffect, useState } from "react";
import {
  FetchPrograms,
  FetchUcoveringSessions,
  SetUncoveringSession,
  SuggestPrograms,
} from "../../../Api";
import styles from "../../../styles/dashboardHome.module.css";
import { useSelector } from "react-redux";
import { cdn_Link } from "../../../config";
import dayjs from "dayjs";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Input, Modal } from "antd";
import { flattenDiagnosticMessageText } from "typescript";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { toast } from "react-toastify";
import Pagination from "../../Pagination/Pagination";
const Uncovering = () => {
  const cookie: any = useSelector((state: any) => state.user.user.token);
  const [activeTab, setActiveTab] = useState(1);
  const [showModal, setshowModal] = useState(false);
  const [isJoinEnabled, setIsJoinEnabled] = useState<any>({});
  const [data, setData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });

  const [programdata, setProgramData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    filter: 0,
    history: [],
  });

  const [uncoverData, setUncoverData] = useState({
    sessionId: "",
    date: "",
    startTime: "",
  });

  const [currentWeek, setCurrentWeek] = useState(dayjs());
  const [sessionsid, setSessionId] = useState("");
  const [myTimeOut, setMytimeOut] = useState();
  const [search, setSearch] = useState("");

  const ScheduleUncovering = async () => {
    SetUncoveringSession({ cookie: cookie, data: uncoverData })
      .then((result: any) => {
        toast.success(result.message);
        setUncoverData({ sessionId: "", date: "", startTime: "" });
        setshowModal(false);
      })
      .catch((err) => console.log(err));
  };

  const handleSubmitUncover = () => {
    ScheduleUncovering();
  };

  const fetchSessions = async () => {
    FetchUcoveringSessions({
      filter: activeTab,
      item_per_page: 4,
      cookie: cookie,
    })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setData(x);
      })
      .catch((err) => console.log(err));
  };

  const fetchPrograms = async () => {
    FetchPrograms({
      token: cookie,
    })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...programdata };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          filter: res.filter,
          history: [...res.history],
        };
        setProgramData(x);
      })
      .catch((err:any) => console.log(err));
  };

  const handleSuggestCourses = async (_id: string) => {
    SuggestPrograms({
      pIds: "",
      usId: _id,
      cookie: cookie,
    })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...data };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setData(x);
      })
      .catch((err:any) => console.log(err));
  };

  const handlePrevWeek = () => {
    setCurrentWeek(currentWeek.subtract(1, "week"));
  };
  const handleNextWeek = () => {
    setCurrentWeek(currentWeek.add(1, "week"));
  };

  const getWeekDates = () => {
    const startOfWeek = currentWeek.startOf("week");
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(startOfWeek.add(i, "day"));
    }
    return days;
  };

  useEffect(() => {
    fetchSessions();
  }, [activeTab]);

  useEffect(() => {
    if (sessionsid.trim() == "") return;
    fetchPrograms();
  }, [sessionsid, programdata.filter]);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedJoinEnabled: any = {};
      data.history?.forEach((elem: any) => {
        const currentTime = dayjs();
        const appointmentTime = dayjs(elem?.appointmentDate);

        updatedJoinEnabled[elem?._id] = currentTime.isAfter(appointmentTime);
      });
      setIsJoinEnabled(updatedJoinEnabled);
    }, 60000);
    return () => clearInterval(interval);
  }, [data.history]);

  useEffect(() => {
    clearTimeout(myTimeOut);
    const timeout: any = setTimeout(() => {
      setData({ ...data, page: 1 });
      fetchSessions();
    }, 700);
    setMytimeOut(timeout);
  }, [search]);

  const MappedLabel: any = {
    1: "Upcomming Sessions",
    2: "Completed Sessions",
    3: "Managed Sessions",
  };

  return (
    <div>
      <Modal
        open={showModal}
        centered
        footer={null}
        width={500}
        bodyStyle={{ width: "100%", overflow: "auto" }}
        onCancel={() => setshowModal(false)}>
        <div>
          <p>Select Date To Schedule the Uncovering</p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}>
            <Button onClick={handlePrevWeek}>
              <NavigateBeforeIcon />
            </Button>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
              }}>
              {getWeekDates().map((date, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    border: "solid 2px black",
                    borderRadius: "5px",
                    padding: "4px",
                    cursor: "pointer",
                    backgroundColor:
                      uncoverData.date === date.format("DD-MM-YYYY")
                        ? "lightgreen"
                        : "white",
                  }}
                  onClick={() =>
                    setUncoverData({
                      ...uncoverData,
                      date: date.format("DD-MM-YYYY"),
                    })
                  }>
                  <p style={{ color: "black" }}>{date.format("MMM")}</p>
                  <p style={{ color: "black" }}>{date.format("D")}</p>
                </div>
              ))}
            </div>
            <Button onClick={handleNextWeek}>
              <NavigateNextIcon />
            </Button>
          </div>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
            }}>
            <p>Select Start Time for Uncovering</p>

            <input
              type="time"
              className="w-full text-sm outline-none hide-calendar "
              value={
                uncoverData.startTime != ""
                  ? uncoverData.startTime
                  : dayjs().format("HH:mm A")
              }
              onChange={(e) =>
                setUncoverData({ ...uncoverData, startTime: e.target.value })
              }
            />
          </div>

          <Button
            type="primary"
            shape="round"
            style={{
              marginTop: "10px",
              height: "max-content",
              padding: "4px",
            }}
            onClick={handleSubmitUncover}>
            Schedule Uncovering
          </Button>
        </div>
      </Modal>
      <Modal
        open={sessionsid.trim() != ""}
        centered
        footer={null}
        width={500}
        bodyStyle={{ width: "100%", overflow: "auto" }}
        onCancel={() => setSessionId("")}>
        <div>
          <div>
            <h4>Suggest Courses</h4>
            <p>filter</p>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Total Sessions</th>
                  <th>Suggested</th>
                </tr>
              </thead>
              {programdata.history.length > 0 &&
                programdata.history.map((elem: any, ind: number) => (
                  <tr key={`${elem._id}`}>
                    <td style={{ textAlign: "start", textWrap: "wrap" }}>
                      {elem.name}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {elem.program_type == 1 ? "Live" : "Recorded"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {elem?.session?.length || 0}
                    </td>
                    <td>C</td>
                  </tr>
                ))}
              <tbody></tbody>
            </table>
          </div>
        </div>
      </Modal>
      <ul className={styles.tabCnt1}>
        <li
          className={`${styles.tab1} ${activeTab === 1 && styles.tabActive1}`}
          onClick={() => setActiveTab(1)}>
          Upcoming Sessions
        </li>
        <li
          className={`${styles.tab1} ${activeTab === 2 && styles.tabActive1}`}
          onClick={() => setActiveTab(2)}>
          Completed Sessions
        </li>
        <li
          className={`${styles.tab1} ${activeTab === 3 && styles.tabActive1}`}
          onClick={() => setActiveTab(3)}>
          Manage Requests
        </li>
      </ul>

      <div className={styles.tables}>
        <table>
          <thead>
            <tr>
              <th>client name</th>
              {/* <th>session type</th> */}
              <th>Session Date</th>
              <th>View Report</th>
              {activeTab == 1 && <th>Join Session</th>}
              {activeTab == 2 && <th>Suggested Courses</th>}
              {activeTab == 3 && <th>Schedule Uncovering</th>}
              {/* {activeTab == 3 && <th>Reschedule</th>} */}
            </tr>
          </thead>
          <tbody>
            {data.history?.map((elem: any, ind: number) => (
              <tr key={`${ind}_${elem._id?.coachee}`}>
                <td className={styles.img_text}>
                  <div>
                    <img
                      src={
                        elem?.CoacheeDetails?.profileImg
                          ? `${cdn_Link}/${elem?.CoacheeDetails?.profileImg}`
                          : "/images/Coach/pic-placeholder.png"
                      }
                      alt="User Img"
                      style={{
                        height: "1.5rem",
                        width: "1.5rem",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                  <div className={styles.names}>
                    {elem?.CoacheeDetails?.name}
                  </div>
                </td>
                <td>
                  {elem?.appointmentDate
                    ? dayjs(elem?.appointmentDate).format(
                        " MMM DD, YYYY hh:mm A"
                      )
                    : "-"}
                </td>
                <td>View Report</td>
                {activeTab == 1 && elem?.meetingLink?.trim() != "" && (
                  <td>
                    <Button disabled={!isJoinEnabled[elem?._id]}>
                      Join Session
                    </Button>
                  </td>
                )}
                {activeTab == 2 && (
                  <td>
                    {elem?.suggestedPrograms?.length > 0 ? (
                      "View Courses"
                    ) : (
                      <p onClick={() => setSessionId(elem._id)}>
                        Suggest Courses
                      </p>
                    )}
                  </td>
                )}
                {!elem?.isApproved && activeTab == 3 && (
                  <td
                    onClick={() => {
                      setUncoverData({ ...uncoverData, sessionId: elem?._id });
                      setshowModal(true);
                    }}>
                    <img src="/images/Coach/reschedule.png" alt="" />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {data.history.length < 1 && (
          <h6 style={{ textAlign: "center", margin: "10px 0 10px 0 " }}>
            No {`${MappedLabel[activeTab]}`}
          </h6>
        )}
        {data.history.length > 0 && (
          <Pagination
            next={data.next}
            page={data.page}
            prev={data.prev}
            decreasePage={() => setData({ ...data, page: data.page + 1 })}
            IncreasePage={() => setData({ ...data, page: data.page + 1 })}
            total_page={data.total_page}
          />
        )}
      </div>
    </div>
  );
};

export default Uncovering;
