export const handleFloat = (value) => {
  if (value != "" && value.includes(".")) {
    return parseFloat(value).toFixed(2);
  } else {
    return value;
  }
};

export const formatNumber = (num) => {
  if (num >= 1e7) {
    return (num / 1e7).toFixed(1) + "L";
  } else if (num >= 1e5) {
    return (num / 1e5).toFixed(1) + "L";
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(1) + "K";
  } else {
    return handleFloat(num?.toString());
  }
};
