import { Typography } from "antd";
import styles from "../../components/Dashboard/Coachee/CSS/dashboardHome.module.css";
import EmailIcon from '@mui/icons-material/Email';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchCoachprogramByID } from "../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { notifyError } from "../../Notify";
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrAfter);

export const PaymentModal = ({ data, comPow, setTypo, setAppDetails,coachId,programData }:{ data:any, comPow:any, setTypo:any, setAppDetails:any,coachId:any,programData:any }) => {
    const user = useSelector((state: RootState) => state.user.user);
    const cookie: any = user?.token;
    const [currentWeekStart, setCurrentWeekStart] = useState(dayjs().startOf('week'));
    const [activeTime, setActiveTime] = useState('')
    const [activeday, setActiveDay] = useState("mon");
    const[progId,setProgId]=useState('')

    const getCurrentWeekDates = () => { return Array.from({ length: 7 }, (_, i) => currentWeekStart.add(i, 'day').format('YYYY-MM-DD')); };

    const handleIncrementWeek = () => {
        setCurrentWeekStart((prev) => prev.add(1, 'week'))
    }
    const handledecrementWeek = () => {

        // if (dayjs().startOf('week') < currentWeekStart.subtract(1, 'week')) {
        //     return
        // } else {
        setCurrentWeekStart((prev) => prev.subtract(1, 'week'))
        // }
    }
    const handleActiveWeek = (day: string,date:any) => {
        
        setActiveDay(day.toLocaleLowerCase())
        setAppDetails((prev:any)=>({...prev,date:date}))
       
    }
    const handleActiveTime = (e: any, stime: any, etime: any) => {
        e.preventDefault();
        setActiveTime(stime)
      
        setAppDetails((prev:any)=>({...prev,starttime:stime,endtime:etime}))


    }
    const handlegetproId=(e:any,progId:any)=>{
        if(!progId){
            notifyError("Please select Program ")
        }
        else if (progId){
            setAppDetails((prev:any)=>({...prev,pId:progId}))
            setProgId(progId)
        }
        
    }
    useEffect(()=>{
        fetchCoachprogramByID(coachId,cookie)
        .then((res:any)=>{
            console.log("tHis is program Data ",res)
        })
    },[cookie])
    return (
        <Typography

            id="modal-modal-description"
            className={styles.typoDesc}>
            
            <div className={styles.widPara}>
              
                {data?.coach.uid.profileImg ? (<img
                    src={`https://dxe2g9i1k0e2c.cloudfront.net/${data?.coach.uid.profileImg}`}
                    className={styles.coachImg}
                />) : (
                    <h4 className={styles.coachIcon}>{data.coach.name?.slice(0, 2).toUpperCase()}</h4>
                )}
                <div className={styles.UpgradeText}>
                    <p>
                        <h5 className={styles.UpgradeText}>{data.coach.uid.name}</h5>
                        <p className={styles.dataIcoCoach}><EmailIcon /> {data.coach.uid.email}</p>
                    </p>
                    <b>{comPow.length > 0 ? (comPow.map((compow: any) => (<>
                        <p className={styles.comPowStyle}>
                            {compow.split(',').map((part: string, i: number) => (
                                <p key={i} className={styles.comPowStyles}>
                                    {part.trim()}
                                </p>
                            ))}
                        </p>
                    </>))) : (<>No Data found!</>)}</b>

                </div>

            </div>
           
            <div className={styles.coachTimeZone}>
                <h6>Coach TimeZone </h6>
                <p>UTC+5:30</p>
            </div>
            <div className={styles.coachProgramDetails} >
                <h6>Select Program</h6>
                <div className={styles.coachDetailsContent}>
                {programData.length>0?(programData.map((program:any)=>(<>
                <div onClick={(e:any)=>handlegetproId(e,program.programDetails?._id)}>
                    <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${program.programDetails?.programImg}`}
                        className={`${styles.coachshowImg} ${progId == program.programDetails?._id && `${styles.programActive}`} `}
                      />
                    <p  className={`${styles.coachshowName} ${progId == program.programDetails?._id && `${styles.programActivePara}`}` }>{program.programDetails?.name}</p>
                    </div>
                </>))):(<></>)}
                </div>
            </div>
            <div className={styles.coachTimeZone}>
                <h6> Available Slot </h6>
                <p>{dayjs().format('MMMM')}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }} className={styles.showAvailCoach}>

                <p onClick={handledecrementWeek} className={styles.nextButtonArrow}><ArrowBackIcon /></p>
                {
                    getCurrentWeekDates().map((elem: any, ind: number) => (
                        <div onClick={() => handleActiveWeek(dayjs(elem).format('ddd'),dayjs(elem).format("DD-MM-YYYY"))} className={`${styles.cursor} ${activeday == dayjs(elem).format('ddd').toLocaleLowerCase() ? `${styles.activeDay}` : ''}`}>
                            <>

                            </>
                            <p className={`${styles.dayspara} `}>{dayjs(elem).format('ddd')}</p>
                            <h6>{dayjs(elem).format("DD")}</h6>
                        </div>
                    ))

                }
                <p onClick={handleIncrementWeek} className={styles.nextButtonArrow}> <ArrowForwardIcon /></p>
            </div>
            <div className={styles.displaystartTime}>
                {/* Showing Slots */}
                {data.availability?.[activeday].length > 0 && data.availability?.[activeday].map((elemu: any, ind: any) =>
                    <>
                 
                        <p className={`${styles.displaystartTimepara} ${activeTime ==  elemu?.starttime ? `${styles.displaystartTimepara1}` : ''}`} onClick={(e: any) => { handleActiveTime(e, elemu?.starttime, elemu?.endtime) }}>{elemu?.starttime} To  {elemu?.endtime} </p>
                    </>)}

            </div>

            <div className={styles.coachTimeZoneList}>

            </div>
           
        </Typography>
    )
}