import style from "./ChatCoach.module.css";

import { useEffect, useState } from "react";

import SendIcon from "@mui/icons-material/Send";
import {
  GetAllMsg,
  SendMsg,
  fetchCoachByCoachee,
  GetCoachMsg,
} from "../../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
const ChatCoach = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [coachData, setCoachData] = useState<any>([]);
  const [chatData, setChatData] = useState<any>([]);
  const [chatId, setChatId] = useState("");
  const [msg, setmsg] = useState("");
  const [updata, setUpdata] = useState(false);
  const [coachId, setcoachId] = useState("");

  useEffect(() => {
    fetchCoachByCoachee(1, cookie).then((res: any) => {
      setCoachData(res.data.history);
    });
  }, [cookie]);

  useEffect(() => {
    if (chatId) {
      GetAllMsg(cookie, chatId,1).then((res: any) => {
        setChatData(res);
        console.log("Enjoy CHatting", res);
      });
    }
  }, [cookie, chatId, updata]);

  const getCoachId = (to: any) => {
    setcoachId(to);
    GetCoachMsg(to, cookie).then((res: any) => {
      setChatId(res.data._id);
    });
  };
  const SendMsgs = () => {
    SendMsg(cookie, chatId, msg).then((res: any) => {
      setmsg("");
      setUpdata(true);
    });
    setUpdata(false);
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      SendMsgs();
    }
  };

  return (
    <>
      <div className={style.ChatContainer}>
        <div className={style.Chatparts}>
          <div className={style.ChatpartsLeft}>
            <div className={style.Chathead}>
              <h4> Coach List </h4>
            </div>
            <div className={style.DisplaycoachContent}>
              {coachData ? (coachData.length > 0
                ? coachData.map((coach: any) => (
                    <div
                      onClick={() => {
                        getCoachId(coach.uid._id);
                      }}
                      className={style.TicketDesc}
                    >
                      <img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.uid.profileImg}`}
                        className={style.CoachImg}
                      />

                      <div className={style.CoachDesc}>
                        <p>{coach.uid?.name}</p>
                        <span>{coach.uid?.email}</span>
                      </div>
                    </div>
                  ))
                : "Please Buy Any program"):('')}
            </div>
          </div>
          <div className={style.ChatpartsRight}>
            <div className={style.chapterHead}>
              
              { coachData ?( coachData.length > 0
                ? coachData.map((coach: any) =>
                    coach.uid._id == coachId ? (
                      <div className={style.chattinghead}>
                        {" "}
                        <img
                          src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.uid.profileImg}`}
                          className={style.CoachImg}
                        />
                        <div className={style.CoachDesce}>
                          <p>{coach.uid?.name}</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )
                : ""):''}
            </div>

            <div className={`${chatData && chatData.history ? (chatData.history.length < 7 ? style.DisplayMsgContent1 : style.DisplayMsgContent) : ''}`}>
             
              {chatData.history ?( chatData.history.length > 0
                ? chatData.history.map((msg: any, index: any) => (
                    <div key={index} className={style.DisplayMsg}>
                     
                      {msg?.from === user?._id ? (
                        <div className={style.msgTextLeft}>
                          <p>  {msg?.text}</p>
                        </div>
                      ) : (
                        <div className={style.msgTextRight}>
                          <p> {msg?.text}</p>
                        </div>
                      )}
                    </div>
                  ))
                : ""):('')}
            </div>
            <div className={style.SendMsgButton}>
              <input
                type="text"
                value={msg}
                onChange={(e: any) => setmsg(e.target.value)}
                onKeyDown={handleKeyDown}
              />

              <button onClick={SendMsgs}>
                <SendIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChatCoach;
