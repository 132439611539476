import { useEffect, useState } from "react";
import styles from "./css/Home.module.css";
import { fetchCoach, fetchContactUs, fetchDashFigure } from "../../../Api";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CachedIcon from '@mui/icons-material/Cached';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { cdn_Link } from "../../../config";
import { formatNumber, handleFloat } from "../../../utils/helper";
type propType = {
  setActiveTab: (val: (v: number) => number) => void;
};

type userType = {
  totalCoachees: number;
  totalCoach: number;
  totalUsers: number;
  UserPer: string;
  coachPer: string;
  coacheePer: string
}

const Home = ({ setActiveTab }: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const [coachData, setCoachData] = useState<any[]>([]);
  const [coacheData, setCoacheData] = useState<any[]>([]);
  const [contact, setContact] = useState<any[]>([]);
  const [data, setData] = useState<userType>({
    totalCoachees: 0,
    totalCoach: 0,
    totalUsers: 0,
    UserPer: "+0",
    coachPer: "+0",
    coacheePer: "+0",
  });

  // totalCoachees
  // totalCoach
  // totalUsers
  // UserPer
  // coachPer
  // coacheePer

  const fetchFigure = () => {
    fetchDashFigure({ token: cookie }).then((result: any) => {
      const { UserPer, coachPer, coacheePer } = result.data;
      const { totalCoachees,
        totalCoach,
        totalUsers } = result.data?.TotalFigures
      setData({
        totalCoachees,
        totalCoach,
        totalUsers,
        UserPer,
        coachPer,
        coacheePer
      })
    }
    ).catch((error: any) => console.log(error)
    )
  }

  useEffect(() => {
    fetchCoach(cookie, 2)
      .then((result: any) => {
        setCoachData(result.data.history);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });

    fetchCoach(cookie, 1)

      .then((result: any) => {
        setCoacheData(result.data.history);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });

    fetchContactUs(cookie, 1)
      .then((result: any) => {
        setContact(result.data.history);
      })
      .catch((error: any) => {
        console.error("Error fetching coach data:", error);
      });
    fetchFigure()
  }, [cookie]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.setContainer}>
          <div className={styles.leftContent}>
            <div className={styles.TotalUsersContent}>
              <div className={styles.TotalUsers}>
                <div className={styles.UsersContent}>
                  <p>TOTAL USERS</p>
                  <img src="/images/Coach/dash-user.png" />
                </div>
                <div className={styles.UsersContent}>
                  <h3>{formatNumber(data.totalUsers)}</h3>
                  <p>
                    {" "}
                    <img src={!data.UserPer.toString().includes('-') ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} />
                    <span style={{ color: !data.UserPer.toString().includes('-') ? '#97cc4f' : '#FF5959' }}>
                      {formatNumber(data.UserPer)}%</span>
                    <p>Since last Month</p>
                  </p>
                </div>
              </div>

              <div className={styles.TotalUsers}>
                <div className={styles.UsersContent}>
                  <p>TOTAL SESSIONS</p>
                  <img src="/images/Coach/carbon_headphones.png" />
                </div>
                <div className={styles.UsersContent}>
                  <h3>{formatNumber(data.totalCoach)}</h3>
                  <p>
                    <img src={!data.coachPer.toString().includes('-') ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} />
                    <span style={{ color: !data.coachPer.toString().includes('-') ? '#97cc4f' : '#FF5959' }}>
                      {formatNumber(data.coachPer)}%</span>
                    <p>Since last Month</p>
                  </p>

                </div>
              </div>
            </div>
            <div className={styles.TotalUsersContent}>
              <div className={styles.TotalUsers}>
                <div className={styles.UsersContent}>
                  <p>TOTAL COACHES</p>
                  <img src="/images/Coach/ProfileImage.png" />
                </div>
                <div className={styles.UsersContent}>
                  <h3>{formatNumber(data.totalCoach)}</h3>
                  <p>
                    {" "}
                    <img src={!data.coachPer.toString().includes('-') ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} />
                    <span style={{ color: !data.coachPer.toString().includes('-') ? '#97cc4f' : '#FF5959' }}>
                      {formatNumber(data.coachPer)}%</span>
                    <p>Since last Month</p>
                  </p>
                </div>
              </div>

              <div className={styles.TotalUsers}>
                <div className={styles.UsersContent}>
                  <p>TOTAL NEW USERS</p>
                  <img src="/images/Coach/profileimg.png" />
                </div>
                <div className={styles.UsersContent}>
                  <h3>{formatNumber(data.totalCoachees)}</h3>
                  <p>
                    <img src={!data.coacheePer.toString().includes('-') ? "/images/Coach/dash-upArrow.png" : "/images/Coach/down-arrow-red.png"} />
                    <span style={{ color: !data.coacheePer.toString().includes('-') ? '#97cc4f' : '#FF5959' }}>
                      {formatNumber(data.coacheePer)}%</span>
                    <p>Since last Month</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.rightContent}>
            <h5>Total Session</h5>
          </div>
        </div>




        {/*  for next one */}
        <div className={styles.CoachTable}>
          <div className={styles.UserListContent}>
            <h4>Users List </h4>
            <h6 onClick={() => setActiveTab(() => 2)}>View All </h6>
          </div>

          <table className={styles.sessiontable}>
            <tr>
              <th>USER NAME</th> <th>EMAIL</th>
              <th>STATUS</th>
              <th>SESSION</th>
              <th>SESSION COMPLETED</th>
            </tr>

            {coachData.length > 0 ? (
              coachData.slice(0, 5).map((coach: any, index: number) => (
                <>
                  <tr>
                    <td>
                      {coach.profileImg ? (<img
                        src={`${cdn_Link}/${coach.profileImg}`}
                        className={styles.coachImg}
                      />) : (<img src="/images/svg/Coach/setting/personal.svg" className={styles.coachImg1} />)}


                      {coach.name}
                    </td>
                    <td>{coach.email}</td>
                    <td className={styles.alignCenter}>
                      {/* {coach.account_status} */}
                      {coach.account_status === 0 ? (<p className={styles.AccStatus0}><HourglassTopIcon />Unverified</p>) : coach.account_status === 1 ?
                        (<p className={styles.AccStatus1}><CachedIcon />Pending</p>) : coach.account_status === 2 ? (<p className={styles.AccStatus2}><ThumbUpAltIcon />Active</p>) : coach.account_status === 3 ?
                          (<p className={styles.AccStatus3}><BlockIcon />Blocked</p>)
                          : coach.account_status === 4 ? (<p className={styles.AccStatus4}><DeleteIcon />Deleted</p>) : (<p className={styles.AccStatus4}><PersonOffIcon />Not Found!</p>)}
                    </td>

                    <td></td>
                  </tr>
                </>
              ))
            ) : (
              <p>No coach data available</p>
            )}
          </table>
        </div>
        <div className={styles.CoachTable}>
          <div className={styles.UserListContent}>
            <h4>Coach List </h4>

            <h6 onClick={() => setActiveTab(() => 2)}>View All </h6>
          </div>
          <table className={styles.sessiontable}>
            <tr>
              <th>USER NAME</th> <th>EMAIL</th>
              <th>STATUS</th>
              <th>SESSION</th>
              <th>SESSION COMPLETED</th>
            </tr>

            {coacheData.length > 0 ? (
              coacheData.slice(0, 5).map((coach: any, index: number) => (
                <>
                  <tr>
                    <td>
                      {coach.profileImg ? (<img
                        src={`https://dxe2g9i1k0e2c.cloudfront.net/${coach.profileImg}`}
                        className={styles.coachImg}
                      />) : (<img src="/images/svg/Coach/setting/personal.svg" className={styles.coachImg1} />)}

                      {coach.name}
                    </td>
                    <td>{coach.email}</td>
                    <td className={styles.alignCenter}>
                      {/* {coach.account_status} */}
                      {coach.account_status === 0 ? (<p className={styles.AccStatus0}><HourglassTopIcon />Unverified</p>) : coach.account_status === 1 ?
                        (<p className={styles.AccStatus1}><CachedIcon />Pending</p>) : coach.account_status === 2 ? (<p className={styles.AccStatus2}><ThumbUpAltIcon />Active</p>) : coach.account_status === 3 ?
                          (<p className={styles.AccStatus3}><BlockIcon />Blocked</p>)
                          : coach.account_status === 4 ? (<p className={styles.AccStatus4}><DeleteIcon />Deleted</p>) : (<p className={styles.AccStatus4}><PersonOffIcon />Not Found!</p>)}
                    </td>
                    <td></td>
                  </tr>
                </>
              ))
            ) : (
              <p>No coach data available</p>
            )}
          </table>
        </div>
        <div className={styles.setContainer}>
          <div className={styles.UserListContent}>
            <h4>Contact Us List </h4>

            <h6 onClick={() => setActiveTab(() => 8)}>View All </h6> </div>
        </div>
        <div className={styles.sessiontable}>
          <table>


            {/* <img src="/images/Coach/ProfileImage.png" className={styles.ContactUsImg} /> */}
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Message</th>
              <th></th>
              <th></th>
            </tr>

            {contact.length > 0 ? (
              contact.slice(0, 5).map((contact: any, index: number) => (
                <>
                  <tr>
                    <td>

                      <img src="/images/svg/Coachee/myCoach.svg" className={styles.ContactUsImg} />
                      {contact.firstName}
                    </td>
                    <td>{contact.email}</td>
                    <td className={styles.alignCenter}>
                      {contact.phoneno}
                    </td>

                    <td> {contact.message.length < 40 ? (<>{contact.message.substring(0, 40)}</>) : (<>{contact.message.substring(0, 40)}...</>)}</td>
                  </tr>
                </>
              ))
            ) : (
              <p>No coach data available</p>
            )}
          </table>
        </div></div>
    </>
  );
};

export default Home;
