import { Button } from "@mui/material";
import styles from "./CSS/dashboardHome.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { fetchCoacheeSession } from "../../../Api";
import dayjs from "dayjs";
const UpcomingCoaching=()=> {
  const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
   const [CoachingData,setCoachingData]=useState<any[]>([])
   const[page,setPage]=useState(1);
  
  useEffect(()=>{
    fetchCoacheeSession(page,cookie)
    .then((result:any)=>{
      console.log(result)
      setCoachingData(result.history)
      
    })
    .catch((error:any)=>{
      console.log("from Card",error)
    })
  },[cookie,page])
  const handlePreviousPage = async () => {
    if (page > 1) {
      await setPage(page - 1);
    }
  };

  const handleNextPage = async () => {
    if (CoachingData.length > 0) {
      await setPage(page + 1);
    }
  };
  return (

    <>
    <div className={styles.dashcontainer}>
        <div className={styles.UpcomingContainer}>
          <div className={styles.Upcoming}>
            <div className={styles.UpcomingP}>
              <p> Upcoming Coachings</p>
            </div>
          </div>
{/* start trial */}
<div className={styles.Recommond}>

{
  CoachingData.length>0?(CoachingData.filter((cdata:any) => dayjs(cdata.date.slice(0, 10)).isSameOrAfter(dayjs())).map((cdata:any)=>(<>
  <div className={styles.coaching}>
   <img src={`https://dxe2g9i1k0e2c.cloudfront.net/${cdata.coach?.profileImg}`} className={styles.coachingImg} />
   <div className={styles.coachingTxt}>
                <p>{cdata.coach?.name}</p>
                <div className={styles.coachEmail}>
                  <span> {cdata.coach?.email.slice(0,14)}..</span>
                  <Button className={styles.coachButtonJoin1}> <a href={cdata.join_url} target="_blank" rel="noopener noreferrer">Join</a></Button>
                </div>
                <div className={styles.coachtime}>
                  <span>
                    <AccessTimeIcon style={{ fill: "#FF5733" }} />
                    {cdata.starttime}

                  </span>
                  <span>
                    {" "}
                    <CalendarMonthIcon style={{ fill: "#FF5733" }} />{dayjs(cdata.date.slice(0,10)).format('DD MMM YYYY')}
                  </span>
                </div>
                </div>
        
                </div>
  </>))):(<>No Any Session selected!</>)
}

</div>
          {/*  Trial form */}
          
        </div>
        <div className={styles.pagination}>
        <button onClick={handlePreviousPage} disabled={page === 1}>
          Previous
        </button>
        <button onClick={handleNextPage} disabled={CoachingData.length === 0}>
          Next
        </button>
      </div>
      </div>

    </>
  )
}

export default UpcomingCoaching