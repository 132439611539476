import { useSelector } from "react-redux";
import CoachSideBar from "./SideBar";
import CoacheeSideBar from "../Coachee/wrapper/SideBar";
import AdminSideBar from "../Admin/wrapper/SideBar";
import style from "./wrapper.module.css";
import { RootState } from "../../../redux/store";
import { useState } from "react";
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';

type propType = {
  activeTab: number;
  setActiveTab: (val: number) => void;
  children?: any;
};
const Wrapper = (props: propType) => {
  const user = useSelector((state: RootState) => state.user.user);
  const [head, setHead] = useState("");
  return (
    <>
      <div className={style.container}>
        <div className={style.bkTop}>
          <img
            src="/images/svg/Coach/shapebk.svg"
            className={style.bkTopInner}
          />
        </div>

        <div className={style.foreGround}>
          <div className={style.innerForeGround}>
            <header className={style.navname}>
              <ul>
                {user?.role ==1 && (
                <li onClick={() => props.setActiveTab(13)}>
                <img src="./images/Coachee/chatShape2.png" className={style.chatShape}/>
                </li> )}
                {user?.role ==2 && (
                <li onClick={() => props.setActiveTab(13)}>
                <img src="./images/Coachee/chatShape2.png" className={style.chatShape}/>
                </li> )}
                <li>
                  <img src="/images/Coach/login/bell.svg" />
                </li>
                {user?.role == 2 && (
                  <>
                    {" "}
                    <li onClick={() => props.setActiveTab(10)}>
                      <img src="/images/Coach/login/help.svg" />
                      Help
                    </li>
                  </>
                )}
                {user?.role == 1 && (
                  <>
                    {" "}
                    <li onClick={() => props.setActiveTab(6)}>
                      <img src="/images/Coach/login/help.svg" />
                      Help
                    </li>
                  </>
                )}
                {user?.role == 0 && (
                  <>
                    {" "}
                    <li onClick={() => props.setActiveTab(6)}>
                      <img src="/images/Coach/login/help.svg" />
                      Help
                    </li>
                  </>
                )}
              </ul>
            </header>

            <div>
              {head != "My Settings" && (
                <div className={style.dashboardheader}>
                  <h1>{head}</h1>
                  {/* <p>hello this is from your {head}</p> */}
                </div>
              )}

              <div className={style.dashboardcontainer}>{props.children}</div>
            </div>

            <footer className={style.footer}>
              Copyright 2023 © DreamSmart Behavioural Solutions™
            </footer>
          </div>
        </div>
        {user?.role === 0 && (
          <AdminSideBar
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
            setHead={setHead}
          />
        )}
        {user?.role === 1 && (
          <CoachSideBar
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
            setHead={setHead}
          />
        )}
        {user?.role === 2 && (
          <CoacheeSideBar
            activeTab={props.activeTab}
            setActiveTab={props.setActiveTab}
            setHead={setHead}
          />
        )}
      </div>
    </>
  );
};

export default Wrapper;
