import React from "react";
import { Button } from "antd";

type propType = {
  prev: boolean;
  next: boolean;
  page: number;
  decreasePage?: any;
  IncreasePage?: any;
  total_page: number;
};

const Pagination = (prop: propType) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        width: "auto",
        right: "10px",
        bottom: "15px",
        alignItems: "center",
        margin: "10px 10px 10px 0",
        padding: "0 20px",
      }}>
      <Button disabled={!prop.prev} onClick={prop.decreasePage}>
        Previous
      </Button>

      {Array.from({ length: prop.total_page }).map((elem, ind) => (
        <Button
          key={`Pagi${ind}`}
          style={{
            height: "20px",
            width: "20px",
            padding: "0",
            fontSize: "12px",
            margin: "0 3px",
            background: prop.page === ind + 1 ? "rgb(255, 51, 51)" : "white",
            color: prop.page === ind + 1 ? "white" : "black",
          }}
          onClick={() => {}}>
          {ind + 1}
        </Button>
      ))}

      <Button disabled={!prop.next} onClick={prop.IncreasePage}>
        Next
      </Button>
    </div>
  );
};
// disabled={!prop.next}

export default Pagination;
