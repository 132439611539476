import React from 'react'
import { Unity, useUnityContext } from "react-unity-webgl";
const Assessment=()=> {
  const { unityProvider } = useUnityContext({
    loaderUrl: "Build/abcd.loader.js",
    dataUrl: "Build/abcd.data.unityweb",
    frameworkUrl: "Build/abcd.framework.js.unityweb",
    codeUrl: "Build/abcd.wasm.unityweb",
  });
  var SendIndexToReact =function (index:any){
    console.log("From Front End: ",index);
    
  }
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <Unity unityProvider={unityProvider} style={{height:'100%',width:'100%'}}/>
    {/* <iframe
      src="https://divyasteam.github.io/dsss.github.io/" 
      width="100%"
      height="100%"
          
      title="Unity Game"
    /> */}
  </div>

  );
}

export default Assessment