import { useEffect, useState } from "react";
import styles from "./programs.module.css";
import style from "../../Coachee/CSS/dashboardHome.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {  fetchCoachProgramByID, fetchCoachprogramByID, FetchProgramsCoach } from "../../../../Api";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { cdn_Link } from "../../../../config";
import { Box,  MenuItem,  Modal, Select, TextField, Typography } from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import dayjs from "dayjs";
type propType = {
    setActiveTab: (val: number) => void;
    pid:any,
    coacheeData:any
  };
 

const ProgramById=({setActiveTab,pid,coacheeData}:propType)=>{
    const user = useSelector((state: RootState) => state.user.user);
  const cookie: any = user?.token;
  const[progSession,setProgSession]=useState<any>([]);
  const[coacheeId,setcoacheeId]=useState('')
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose= () => setOpen(false);
  const [programData, setprogramData] = useState({
    prev: false,
    next: false,
    search: "",
    page: 1,
    total_page: 1,
    history: [],
  });
  useEffect(()=>{
    fetchCoachProgramByID(pid,cookie)
    .then((res:any)=>{
        console.log("this is res program by id :",res.data.history)
        setProgSession(res.data.history)
    })
  },[cookie])
  const fetchProgram = () => {
    FetchProgramsCoach({
      cookie,
      page: programData.page,
      search: programData.search,
    })
      .then((result: any) => {
        let res = result.data;
        let x: any = { ...programData };
        x = {
          ...x,
          next: res.next,
          prev: res.prev,
          history: [...res.history],
        };
        setprogramData(x);
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchProgram();
  }, [cookie]);
  
  const handleCardVal = (event: any) => {

    setcoacheeId(event.target.value)
  }
    return (<> 
    <div className={styles.programsDisplay}>
      {programData?(
        programData.history.length>0?(programData.history.map((progdata:any)=>(progdata._id==pid?(<>
        <div className={styles.programHead}>
           
                <img src={`${cdn_Link}/${progdata.programImg}`}  className={styles.progImg}/>
                <div>
                    <h6>{progdata.name}</h6>
                    <p><AccessAlarmsIcon/> {dayjs(progdata.createdAt.slice(0,10)).format('DD MMM YYYY')}</p>
                </div>
            
        </div>
        <div className={styles.ProgDesc}>
            <br/>
            <br/>
            <h6>Description</h6>
            <p>{progdata.description}</p>
        </div>
<div className={styles.totalSess}>
    <div className={styles.totalSessHead}>
    <h6>Total Session</h6>
    <button className={styles.totalSessHeadBtn} onClick={()=>handleOpen()}><LibraryAddIcon/> Create Sessions</button>
    <Modal
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={style.BoxStyle}>
              <span
                className={style.HandleClose}
                onClick={() => handleClose()}
              >
                <CloseTwoToneIcon className={style.HandleCloseIcon} />
              </span>
              <Typography id="modal-modal-title" variant="h5" component="h2">
              Create New Session 
              </Typography>
              <Typography
                id="modal-modal-description"
                className={style.typoDesc}
              >
                
              <TextField
            type="text"
            id="outlined-basic"
            label="Session Topic"
            variant="outlined"
            // name="name"
            // value={title}
            // onChange={(e: any) => setTitle(e.target.value)}
            className={style.Inputfield}
            InputProps={{
              className: `${style.muiInput}`,
              
            }}
            sx={{
              my: 1,
              "& .MuiInputBase-input": {
                width: "100%",
                
              },
              "& .MuiInputLabel-root": {
                backgroundColor: "#fff",
    },
            }}/>
             <Select
                        onChange={handleCardVal}
                        className={styles.cardDesignSelect}
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Select Card"
                      >
                         {coacheeData? (coacheeData.length>0?(coacheeData.map((coachee:any)=>(coachee._id == pid?(coachee.coacheIds.map((coachee:any)=>(
                          <MenuItem  key={coachee.id} value={coachee.id} className={styles.menuDesign}>
                          {coachee.name}
                          </MenuItem>
                         ))):'' ))):''):'No coachee Buy program!'}
  

                      </Select>
              </Typography>
              </Box>
              </Modal>
              
     </div>
     <table>
        <tr>
        <th>SESSION TOPIC</th>
        <th>WHAT ARE WE GOING TO LEARN</th>
        
        <th>DATE</th>
        <th></th>
        </tr>
      {progSession?(
        progSession.length>0? (progSession.map((session:any)=>(<> <tr>
            {/* <td>{session.title}</td> */}
            <td className={styles.groupIcTd}><span className={styles.groupIc}>{session.title.substring(0,2).toUpperCase()}</span> {session.title.slice(0, 15)} </td>
            <td>{session.expected.slice(0,30)}</td>
           
            <td>{dayjs(session.updatedAt.slice(0,10)).format('DD MMM YYYY')}</td>
            <td>{session.isCompleted==false? <p className={styles.groupUpcome}><AccessTimeIcon/> Upcoming</p>:<p className={styles.groupComplete}><TaskAltIcon/> Completed</p>}</td>
             </tr></>))):'No data Found !'
            ) :'No data Found !'}
           
    </table>
</div>
    
    </>):('')))):('No data Found')
  ):('No data Found')}
    </div>
    </>)
}
export default ProgramById;